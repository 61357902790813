typeof window !== "undefined" ? require("boxicons") : null;
import React from "react";
import BiverCRM from "../assets/icons/Biver.svg";
import BiverFranquias from "../assets/icons/BiverFranquias.svg";
import Button from "../components/Button";
import SEO from "../components/seo";

export default function Index() {
  return (
    <div className="grid md:grid-cols-2 min-h-screen flex">
      <SEO
        title="Biver"
        keywords={[
          `Biver`,
          `CRM`,
          `Franquias`,
          `Vendedores`,
          `Vendas`,
          `Gestão`,
        ]}
      />
      <div className="background-white grid">
        <img src={BiverCRM} className="p-8 w-3/5 md:w-1/3" alt="" />
        <div className="my-auto mx-10">
          <h1 className="font-bold">
            A melhor ferramenta para gerenciar suas vendas
          </h1>
          <h3 className="text-gray my-4">
            O Biver CRM torna seu processo de vendas mais prático auxiliando
            você a fechar mais negócios.
          </h3>
          <h3 className="text-gray my-4">
            Junte-se as diversas empresas que confiam no Biver.
          </h3>
          <a href="https://bivercrm.com.br">
            <Button className="mt-4">Saiba mais</Button>
          </a>
        </div>
        <div className="flex mx-8 mb-4 mt-6 md:mt-auto">
          <a
            href="https://www.facebook.com/bivercrmfranquias"
            rel="noopener noreferrer"
            target="_blank"
          >
            <box-icon name="facebook" type="logo" color="#e57668" />
          </a>
          <a
            className="ml-4"
            href="https://www.instagram.com/biver_crm_franquias/"
            rel="noopener noreferrer"
            target="_blank"
          >
            <box-icon name="instagram" type="logo" color="#e57668" />
          </a>
        </div>
        <small className="text-gray mx-3 md:mx-8">
          Biver CRM© 2020. Todos os direitos reservados.
        </small>
      </div>

      <div className="background-blue grid">
        <img src={BiverFranquias} className="p-8 w-4/6 md:w-2/5" alt="" />
        <div className="my-auto mx-10">
          <h1 className="text-white font-bold">
            A melhor ferramenta para gerenciar sua rede de franqueados
          </h1>
          <h3 className="text-white my-4">
            O Biver Franquias torna mais eficaz a comunicação entre franquia e
            franqueados.
          </h3>
          <h3 className="text-white my-4">
            Faça parte das empresas que estão gerenciando sua rede através do
            Biver.
          </h3>
          <a href="https://biverfranquias.com.br">
            <Button className="mt-4">Saiba mais</Button>
          </a>
        </div>
        <div className="flex m-8 mb-4 mt-6 md:mt-auto">
          <a
            href="https://www.facebook.com/bivercrmfranquias"
            rel="noopener noreferrer"
            target="_blank"
          >
            <box-icon name="facebook" type="logo" color="#fff" />
          </a>
          <a
            className="ml-4"
            href="https://www.instagram.com/biver_crm_franquias/"
            rel="noopener noreferrer"
            target="_blank"
          >
            <box-icon name="instagram" type="logo" color="#fff" />
          </a>
        </div>
        <small className="text-white mx-3 md:mx-8">
          Biver Franquias© 2020. Todos os direitos reservados.
        </small>
      </div>
    </div>
  );
}
